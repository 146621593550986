var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "grid grid-cols-6 gap-6 gap-y-8 my-8" },
    [
      _c(
        "div",
        { staticClass: "col-span-3" },
        [
          _c("fl-labeled-input", {
            attrs: {
              rules: { required: true, max: 255 },
              label: "作品名",
              fieldId: "film_name",
            },
            model: {
              value: _vm.value.translations.ja.film_name,
              callback: function ($$v) {
                _vm.$set(_vm.value.translations.ja, "film_name", $$v)
              },
              expression: "value.translations.ja.film_name",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-span-3" },
        [
          _c("fl-labeled-input", {
            attrs: {
              label: "作品名",
              sublabel: "英語",
              fieldId: "film_name_en",
              rules: { required: true, max: 255, english: true },
            },
            model: {
              value: _vm.value.translations.en.film_name,
              callback: function ($$v) {
                _vm.$set(_vm.value.translations.en, "film_name", $$v)
              },
              expression: "value.translations.en.film_name",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-start-1 col-span-6" },
        [
          _c("fl-labeled-input", {
            attrs: {
              label: "フリガナ",
              rules: { required: _vm.isRequired, max: 255 },
              fieldId: "film_name_yomi",
              explanation: "読み仮名をカタカナだけで入力してください。",
            },
            model: {
              value: _vm.value.film_name_yomi,
              callback: function ($$v) {
                _vm.$set(_vm.value, "film_name_yomi", $$v)
              },
              expression: "value.film_name_yomi",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-start-1 col-span-6" },
        [
          _c("fl-labeled-input", {
            attrs: {
              label: "日本語作品名のローマ字表記",
              rules: { required: _vm.isRequired, max: 255 },
              fieldId: "original_name",
              explanation: "半角英数字のみで入力してください。",
            },
            model: {
              value: _vm.value.original_name,
              callback: function ($$v) {
                _vm.$set(_vm.value, "original_name", $$v)
              },
              expression: "value.original_name",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-start-1 col-span-3" },
        [
          _c("fl-select", {
            attrs: {
              label: "動画区分(選択)",
              required: _vm.isRequired,
              options: _vm.filmType,
              fieldId: "film_type",
            },
            model: {
              value: _vm.value.film_type,
              callback: function ($$v) {
                _vm.$set(_vm.value, "film_type", $$v)
              },
              expression: "value.film_type",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-start-1 col-span-6" },
        [
          _c("fl-labeled-input", {
            attrs: {
              type: "number",
              label: "上映時間",
              rules: { required: _vm.isRequired, integer: true, max: 4 },
              explanation:
                "分数を、数字のみで入力ください。TVシリーズの場合には1話分の分数をご記入ください。各話で分数に差異がある場合には、シリーズ内でもっともスタンダードな分数をご記入ください。",
              fieldId: "play_time",
            },
            model: {
              value: _vm.value.play_time,
              callback: function ($$v) {
                _vm.$set(_vm.value, "play_time", _vm._n($$v))
              },
              expression: "value.play_time",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-start-1 col-span-4" },
        [
          _c("fl-select-lanugages", {
            attrs: {
              isRequired: _vm.isRequired,
              options: _vm.subtitleLanguage,
            },
            model: {
              value: _vm.value.main_video,
              callback: function ($$v) {
                _vm.$set(_vm.value, "main_video", $$v)
              },
              expression: "value.main_video",
            },
          }),
        ],
        1
      ),
      _vm._m(0),
      _c(
        "div",
        { staticClass: "col-start-1 col-span-3" },
        [
          _c("fl-select", {
            attrs: {
              label: "製作国",
              required: _vm.isRequired,
              options: _vm.productionCountry,
              fieldId: "production_country",
            },
            model: {
              value: _vm.value.production_country,
              callback: function ($$v) {
                _vm.$set(_vm.value, "production_country", $$v)
              },
              expression: "value.production_country",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-start-1 col-span-6" },
        [
          _c("fl-labeled-input", {
            attrs: {
              fieldId: "production_year",
              rules: { required: _vm.isRequired, max: 4 },
              type: "number",
              label: "公開年",
              explanation: "半角英数字のみで入力してください。",
            },
            model: {
              value: _vm.value.production_year,
              callback: function ($$v) {
                _vm.$set(_vm.value, "production_year", $$v)
              },
              expression: "value.production_year",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-start-1 col-span-3" },
        [
          _c("fl-select", {
            attrs: {
              label: "音声言語",
              required: _vm.isRequired,
              options: _vm.language,
              fieldId: "language",
              unselected: true,
            },
            model: {
              value: _vm.value.language,
              callback: function ($$v) {
                _vm.$set(_vm.value, "language", $$v)
              },
              expression: "value.language",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-start-1 col-span-3" },
        [
          _c("fl-select", {
            attrs: {
              label: "種類",
              required: _vm.isRequired,
              options: _vm.genreType,
              fieldId: "type",
              unselected: true,
            },
            model: {
              value: _vm.value.type,
              callback: function ($$v) {
                _vm.$set(_vm.value, "type", $$v)
              },
              expression: "value.type",
            },
          }),
        ],
        1
      ),
      _c(
        "label",
        { staticClass: "col-start-1 col-span-1 mt-3 text-right text-sm" },
        [_vm._v("ジャンル")]
      ),
      _c(
        "div",
        { staticClass: "col-span-5 relative" },
        [
          _c("InputGenreField", {
            staticClass: "w-full",
            attrs: { is_main: true },
            model: {
              value: _vm.value.genres,
              callback: function ($$v) {
                _vm.$set(_vm.value, "genres", $$v)
              },
              expression: "value.genres",
            },
          }),
          _c("ValidateParts", {
            attrs: {
              value: _vm.mainGenres,
              vid: "mainGenres",
              rules: { required: _vm.isRequired },
              name: "ジャンル",
              errorDisplaySize: "text-xxs",
            },
          }),
        ],
        1
      ),
      _c(
        "label",
        { staticClass: "col-start-1 col-span-1 mt-3 text-right text-sm" },
        [_vm._v("サブジャンル")]
      ),
      _c(
        "div",
        { staticClass: "col-span-5 relative" },
        [
          _c("InputGenreField", {
            staticClass: "w-full",
            attrs: { is_main: false },
            model: {
              value: _vm.value.genres,
              callback: function ($$v) {
                _vm.$set(_vm.value, "genres", $$v)
              },
              expression: "value.genres",
            },
          }),
          _c("ValidateParts", {
            attrs: {
              value: _vm.subGenres,
              vid: "subGenres",
              rules: { required: _vm.isRequired },
              name: "サブジャンル",
              errorDisplaySize: "text-xxs",
            },
          }),
        ],
        1
      ),
      _vm.value.film_type === 1
        ? [
            _c(
              "div",
              { staticClass: "col-start-1 col-span-3" },
              [
                _c("fl-labeled-input", {
                  attrs: {
                    label: "シーズン名",
                    rules: { max: 100 },
                    fieldId: "season_name",
                    sublabel: "日本語",
                  },
                  model: {
                    value: _vm.value.translations.ja.season_name,
                    callback: function ($$v) {
                      _vm.$set(_vm.value.translations.ja, "season_name", $$v)
                    },
                    expression: "value.translations.ja.season_name",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-span-3" },
              [
                _c("fl-labeled-input", {
                  attrs: {
                    label: "シーズン名",
                    rules: { max: 100 },
                    fieldId: "season_name",
                    sublabel: "英語",
                  },
                  model: {
                    value: _vm.value.translations.en.season_name,
                    callback: function ($$v) {
                      _vm.$set(_vm.value.translations.en, "season_name", $$v)
                    },
                    expression: "value.translations.en.season_name",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-start-1 col-span-3" },
              [
                _c("fl-labeled-input", {
                  attrs: {
                    label: "総話数",
                    type: "number",
                    rules: { max: 4 },
                    fieldId: "episode_number",
                  },
                  model: {
                    value: _vm.value.episode_number,
                    callback: function ($$v) {
                      _vm.$set(_vm.value, "episode_number", $$v)
                    },
                    expression: "value.episode_number",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-start-1 col-span-3" },
              [
                _c("fl-labeled-input", {
                  attrs: {
                    label: _vm.$t("commons.fields.episode_name_first"),
                    rules: { max: 255 },
                    fieldId: "episode_name",
                  },
                  model: {
                    value: _vm.value.translations.ja.episode_name,
                    callback: function ($$v) {
                      _vm.$set(_vm.value.translations.ja, "episode_name", $$v)
                    },
                    expression: "value.translations.ja.episode_name",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-span-3" },
              [
                _c("fl-labeled-input", {
                  attrs: {
                    label: _vm.$t("commons.fields.episode_name_first"),
                    sublabel: "英語",
                    rules: { max: 255, english: true },
                    fieldId: "episode_name",
                  },
                  model: {
                    value: _vm.value.translations.en.episode_name,
                    callback: function ($$v) {
                      _vm.$set(_vm.value.translations.en, "episode_name", $$v)
                    },
                    expression: "value.translations.en.episode_name",
                  },
                }),
              ],
              1
            ),
          ]
        : _vm._e(),
      _c(
        "div",
        { staticClass: "col-start-1 col-span-3" },
        [
          _c("fl-labeled-input", {
            attrs: {
              label: _vm.$t("commons.fields.original_from"),
              sublabel: "日本語(50文字以内)",
              rules: { max: 255 },
              fieldId: "original_from",
            },
            model: {
              value: _vm.value.translations.ja.original_from,
              callback: function ($$v) {
                _vm.$set(_vm.value.translations.ja, "original_from", $$v)
              },
              expression: "value.translations.ja.original_from",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-span-3" },
        [
          _c("fl-labeled-input", {
            attrs: {
              label: _vm.$t("commons.fields.original_from"),
              sublabel: "英語(50文字以内)",
              rules: { max: 255, english: true },
              fieldId: "original_from",
            },
            model: {
              value: _vm.value.translations.en.original_from,
              callback: function ($$v) {
                _vm.$set(_vm.value.translations.en, "original_from", $$v)
              },
              expression: "value.translations.en.original_from",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-start-1 col-span-3" },
        [
          _c("fl-select", {
            attrs: {
              label: "レイティング",
              options: _vm.rating,
              fieldId: "rating",
              unselected: true,
            },
            model: {
              value: _vm.value.rating,
              callback: function ($$v) {
                _vm.$set(_vm.value, "rating", $$v)
              },
              expression: "value.rating",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-start-1 col-span-3" },
        [
          _c("fl-select", {
            attrs: {
              label: "吹き替え言語",
              options: _vm.subtitleLanguage,
              fieldId: "voice_language",
              unselected: true,
              nullable: true,
            },
            model: {
              value: _vm.value.main_video.videos[0].embedded_voiceover_language,
              callback: function ($$v) {
                _vm.$set(
                  _vm.value.main_video.videos[0],
                  "embedded_voiceover_language",
                  $$v
                )
              },
              expression:
                "value.main_video.videos[0].embedded_voiceover_language",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-start-1 col-span-6" },
        [
          _c("fl-url-input", {
            attrs: {
              label: "公式サイト",
              explanation: "公式サイト、または内容がわかるURLを入力ください",
              rules: { max: 255, url: true },
              fieldId: "original_site",
            },
            model: {
              value: _vm.value.original_site,
              callback: function ($$v) {
                _vm.$set(_vm.value, "original_site", $$v)
              },
              expression: "value.original_site",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-start-1 col-span-3" },
        [
          _c("fl-url-input", {
            attrs: {
              rules: { max: 255, url: true },
              label: "Twitter",
              fieldId: "twitter_url",
            },
            model: {
              value: _vm.value.twitter_url,
              callback: function ($$v) {
                _vm.$set(_vm.value, "twitter_url", $$v)
              },
              expression: "value.twitter_url",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-start-1 col-span-3" },
        [
          _c("fl-url-input", {
            attrs: {
              label: "Instagram",
              rules: { max: 255, url: true },
              fieldId: "Instagram_url",
            },
            model: {
              value: _vm.value.instagram_url,
              callback: function ($$v) {
                _vm.$set(_vm.value, "instagram_url", $$v)
              },
              expression: "value.instagram_url",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-start-1 col-span-3" },
        [
          _c("fl-url-input", {
            attrs: {
              label: "Facebook",
              rules: { max: 255, url: true },
              fieldId: "facebook_url",
            },
            model: {
              value: _vm.value.facebook_url,
              callback: function ($$v) {
                _vm.$set(_vm.value, "facebook_url", $$v)
              },
              expression: "value.facebook_url",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-start-1 col-span-3" },
        [
          _c("fl-url-input", {
            attrs: {
              label: "IMDB URL",
              rules: { url: true },
              fieldId: "imdb_url",
            },
            model: {
              value: _vm.value.imdb_url,
              callback: function ($$v) {
                _vm.$set(_vm.value, "imdb_url", $$v)
              },
              expression: "value.imdb_url",
            },
          }),
        ],
        1
      ),
      [
        _c(
          "div",
          { staticClass: "col-start-1 col-span-1 my-auto text-right text-sm" },
          [_vm._v("コピーライト(著作権表示)")]
        ),
        _c("FlInput", {
          staticClass: "col-span-2 -mt-6 -mb-4",
          attrs: {
            label: "日本語(150文字以内)",
            rules: { required: _vm.isRequired },
          },
          model: {
            value: _vm.value.translations.ja.copyright,
            callback: function ($$v) {
              _vm.$set(_vm.value.translations.ja, "copyright", $$v)
            },
            expression: "value.translations.ja.copyright",
          },
        }),
        _c("FlInput", {
          staticClass: "col-span-2 col-start-5 -mt-6 -mb-4",
          attrs: {
            label: "英語(150文字以内)",
            rules: { required: _vm.isRequired },
          },
          model: {
            value: _vm.value.translations.en.copyright,
            callback: function ($$v) {
              _vm.$set(_vm.value.translations.en, "copyright", $$v)
            },
            expression: "value.translations.en.copyright",
          },
        }),
      ],
      _c(
        "div",
        { staticClass: "col-start-1 col-span-3" },
        [
          _c("fl-labeled-input", {
            attrs: {
              rules: { max: 255 },
              label: "ISAN Number",
              fieldId: "isan_number",
            },
            model: {
              value: _vm.value.isan_number,
              callback: function ($$v) {
                _vm.$set(_vm.value, "isan_number", $$v)
              },
              expression: "value.isan_number",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-span-6" },
        [
          _c("fl-translatable-textarea", {
            attrs: {
              fieldId: "outline",
              rules: { required: _vm.isRequired, max: 1000 },
              rules2: { required: _vm.isRequired, max: 4000 },
              label: "あらすじ",
              label2: "(日本語1000文字、英語4000文字まで)",
            },
            model: {
              value: _vm.value.translations,
              callback: function ($$v) {
                _vm.$set(_vm.value, "translations", $$v)
              },
              expression: "value.translations",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-span-6" },
        [
          _c("fl-translatable-textarea", {
            attrs: {
              fieldId: "pr_message",
              rules: { required: _vm.isRequired, max: 1000 },
              rules2: { required: _vm.isRequired, max: 4000 },
              label: "PRメッセージ",
              label2: "(日本語1000文字、英語4000文字まで)",
            },
            model: {
              value: _vm.value.translations,
              callback: function ($$v) {
                _vm.$set(_vm.value, "translations", $$v)
              },
              expression: "value.translations",
            },
          }),
        ],
        1
      ),
      _c(
        "label",
        { staticClass: "col-start-1 col-span-1 mt-3 text-right text-sm" },
        [_vm._v("タグ")]
      ),
      _c(
        "div",
        { staticClass: "col-span-5 relative" },
        [
          _c("InputTagsField", {
            model: {
              value: _vm.value.tags,
              callback: function ($$v) {
                _vm.$set(_vm.value, "tags", $$v)
              },
              expression: "value.tags",
            },
          }),
          _c(
            "p",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.imageErrors.tags,
                  expression: "imageErrors.tags",
                },
              ],
              staticClass: "text-xs text-left text-red-600 mt-1",
            },
            [_vm._v(_vm._s(_vm.imageErrors.tags))]
          ),
          _c(
            "p",
            { staticClass: "bg-light-grey rounded border text-sm p-2 mt-4" },
            [
              _vm._v(
                " 購入希望社様が、映画を検索で探した際に、この「タグ」に設定されたキーワードが一致した場合に、検索結果として表示されます。 ですので、各映画と一致するものを選択ください。複数選択が可能です。 なお、「タグ」は英語で検索されるため、英語が基準となっており、日本語では少し意味が分かりにくい場合がありますが、ご了承ください。また、タグは検索用の内部情報なので情報プレビューでは表示されません "
              ),
            ]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      { staticClass: "col-span-4 my-auto text-xs text-left col-start-2 -my-4" },
      [
        _c("span", { staticClass: "bg-off-yellow-lighter p-1 font-bold" }, [
          _vm._v("字幕情報登録には、「確定」ボタンを押す必要があります"),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }