var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "grid grid-cols-12 gap-6 gap-y-8 my-8" },
    [
      _vm._m(0),
      _c("div", { staticClass: "col-span-12" }, [
        _c(
          "div",
          {
            staticClass:
              "w-full h-full mr-6 border-dashed border-2 border-very-light-grey flex",
            class: [_vm.isDrag ? "bg-gray-200" : "bg-white"],
            on: {
              dragover: function ($event) {
                $event.preventDefault()
                return _vm.checkDrag($event, true)
              },
              dragleave: function ($event) {
                $event.preventDefault()
                return _vm.checkDrag($event, false)
              },
              drop: function ($event) {
                $event.preventDefault()
                return _vm.inputFile($event, "drop")
              },
            },
          },
          [
            _c("div", { staticClass: "w-full my-4 mx-auto text-center" }, [
              _vm._m(1),
              _c(
                "button",
                {
                  staticClass:
                    "px-6 py-3 mb-2 mx-auto text-sm font-semibold border border-brown-grey",
                  on: {
                    click: function ($event) {
                      return _vm.$refs.file.click()
                    },
                  },
                },
                [_vm._v(" ファイルを選択 ")]
              ),
              _c(
                "div",
                { staticClass: "ml-2 text-xxs font-semibold tracking-widest" },
                [_vm._v("ファイルをドラッグするか選択してください。")]
              ),
              _c(
                "div",
                { staticClass: "ml-2 text-xxs font-semibold text-brown-grey" },
                [_vm._v("最大アップロードサイズ: 40MB")]
              ),
              _c(
                "div",
                { staticClass: "ml-2 text-xxs font-semibold text-brown-grey" },
                [
                  _vm._v(
                    " ファイル名には半角英数字テキストのみご使用ください。ファイル形式は、jpg/pngでお願いします。 "
                  ),
                ]
              ),
              _c("input", {
                ref: "file",
                staticClass: "hidden",
                attrs: {
                  type: "file",
                  accept: "image/*",
                  multiple: "multiple",
                },
                on: {
                  change: function ($event) {
                    return _vm.inputFile($event, "select")
                  },
                },
              }),
            ]),
          ]
        ),
      ]),
      _vm._m(2),
      _vm._l(_vm.galleryImage, function (image, key) {
        return _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: key <= _vm.displayImageCount,
                expression: "key <= displayImageCount",
              },
            ],
            key: key,
            staticClass: "col-span-4 relative",
          },
          [
            _c("img", {
              staticClass: "movie-image w-full object-cover object-center",
              attrs: { src: _vm.displayImage(image) },
            }),
            _c(
              "button",
              {
                staticClass: "absolute top-0 left-0 select-main-btn",
                on: {
                  click: function ($event) {
                    return _vm.selectMainImage(image, key)
                  },
                },
              },
              [
                _vm.selectedMainImage &&
                _vm.selectedMainImage.source == image.file.url &&
                _vm.selectedMainImage.index == key
                  ? _c("img", {
                      staticClass: "w-5 h-5",
                      attrs: {
                        src: require("@/assets/image/select_img_on.svg"),
                      },
                    })
                  : _c("img", {
                      staticClass: "w-5 h-5",
                      attrs: {
                        src: require("@/assets/image/select_img_off.svg"),
                      },
                    }),
              ]
            ),
            _c(
              "button",
              {
                staticClass:
                  "absolute bottom-0 right-0 select-main-btn mb-1 mr-1 image-shadow",
                on: {
                  click: function ($event) {
                    return _vm.removeMainImage(image, key)
                  },
                },
              },
              [
                _c("img", {
                  staticClass: "w-5 h-5",
                  attrs: {
                    src: require("@/assets/image/trash_icon_white.svg"),
                  },
                }),
              ]
            ),
          ]
        )
      }),
      _c(
        "p",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.imageErrors,
              expression: "imageErrors",
            },
          ],
          staticClass: "col-span-6 text-xxs text-left text-red-600",
        },
        [_vm._v(_vm._s(_vm.imageErrors))]
      ),
      _vm.main_images.length > _vm.displayImageCount
        ? _c("div", { staticClass: "col-start-4 col-span-6" }, [
            _c(
              "button",
              {
                staticClass: "w-4/5 py-2 mx-auto bg-light-grey font-bold",
                on: {
                  click: function ($event) {
                    _vm.displayImageCount += 8
                  },
                },
              },
              [_vm._v("+ More")]
            ),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-span-12" }, [
      _c("p", [_vm._v("場面写真をアップロードしてください。")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "w-5/12 mb-2 pl-1 -mt-1 mx-auto flex items-center justify-center",
      },
      [
        _c("img", {
          staticClass: "h-12 w-12",
          attrs: { src: require("@/assets/image/upload.svg") },
        }),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-span-12 text-left" }, [
      _c("p", { staticClass: "font-bold" }, [
        _vm._v("メインビジュアルは★マークを選択してください。"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }