var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "w-full border border-gray-200 rounded" }, [
    _c("table", { staticClass: "divide-y divide-gray-200 w-full" }, [
      _vm.hasHeader
        ? _c("thead", { staticClass: "bg-gray-100" }, [
            _c(
              "tr",
              [
                _vm._l(_vm.parsedTitles, function (item, titleKey) {
                  return _c(
                    "th",
                    {
                      key: titleKey,
                      staticClass: "p-1",
                      class: _vm.parsedClasses[titleKey],
                    },
                    [_vm._v(" " + _vm._s(item) + " ")]
                  )
                }),
                _vm.hasControls ? _c("th") : _vm._e(),
              ],
              2
            ),
          ])
        : _vm._e(),
      _c(
        "tbody",
        { staticClass: "bg-white divide-y divide-gray-200" },
        [
          _vm.value && _vm.value.length
            ? [
                _vm._l(_vm.value, function (rowValue, rowKey) {
                  return [
                    rowKey === _vm.editingRow
                      ? [
                          _c(
                            "ValidationObserver",
                            {
                              key: "row_" + rowKey,
                              ref: "editObserver",
                              refInFor: true,
                              attrs: { tag: "tr" },
                            },
                            [
                              _vm._t("editingCell", null, {
                                rowValue: _vm.editingRowData,
                                isNew: false,
                              }),
                              _vm.hasControls
                                ? _c("td", { staticClass: "text-right p-1" }, [
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "ml-2 h-10 px-2 mt-2 bg-brown-grey hover:bg-dark-grey active:bg-light-grey disabled:bg-bg-very-light-grey disabled:cursor-not-allowed",
                                        on: {
                                          click: function ($event) {
                                            return _vm.editCancel()
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("controls.cancel")) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "ml-2 h-10 px-2 mt-2 bg-off-yellow hover:bg-off-yellow-lighter active:bg-off-yellow-darker disabled:bg-very-light-grey disabled:cursor-not-allowed",
                                        on: {
                                          click: function ($event) {
                                            return _vm.editDone()
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("controls.save")) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                            ],
                            2
                          ),
                        ]
                      : [
                          _c(
                            "tr",
                            { key: "row_" + rowKey },
                            [
                              _vm._l(
                                _vm.parsedKeys,
                                function (item, columnKey) {
                                  return _c(
                                    "td",
                                    {
                                      key: "column_" + columnKey,
                                      staticClass: "p-1",
                                    },
                                    [
                                      _vm._t(
                                        "cell",
                                        function () {
                                          return [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.stringForKey(
                                                    rowValue,
                                                    item
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        },
                                        { cellKey: item, rowValue: rowValue }
                                      ),
                                    ],
                                    2
                                  )
                                }
                              ),
                              _vm.hasControls
                                ? _c(
                                    "td",
                                    { staticClass: "p-1 text-right" },
                                    [
                                      _vm._t("controls", null, {
                                        rowValue: rowValue,
                                        editClicked: _vm.editClicked,
                                      }),
                                    ],
                                    2
                                  )
                                : _vm._e(),
                            ],
                            2
                          ),
                        ],
                  ]
                }),
              ]
            : _vm._e(),
          _vm.alwaysShowNew && _vm.editingRow === -1
            ? _c(
                "ValidationObserver",
                { ref: "createObserver", attrs: { tag: "tr" } },
                [
                  _vm._t("editingCell", null, {
                    rowValue: _vm.newValue,
                    isNew: true,
                  }),
                  _vm.hasControls
                    ? _c("td", { staticClass: "text-right p-1" }, [
                        _c(
                          "button",
                          {
                            staticClass:
                              "ml-2 h-10 px-2 mt-2 bg-off-yellow hover:bg-off-yellow-lighter active:bg-off-yellow-darker disabled:bg-very-light-grey disabled:cursor-not-allowed",
                            on: { click: _vm.createNew },
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.$t("controls.create")) + " "
                            ),
                          ]
                        ),
                      ])
                    : _vm._e(),
                ],
                2
              )
            : _vm._e(),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }