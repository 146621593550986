var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "w-full grid gap-x-6", class: _vm.fieldGrid },
    [
      _vm.labels.length > 0
        ? _c(
            "label",
            { staticClass: "col-span-2 my-auto text-right text-sm" },
            [
              _c("p", [_vm._v(_vm._s(_vm.labels[0]))]),
              _vm.labels[1]
                ? _c("p", { staticClass: "text-xxs" }, [
                    _vm._v(_vm._s(_vm.labels[1])),
                  ])
                : _vm._e(),
              _vm.labels[2]
                ? _c("p", { staticClass: "text-xxs" }, [
                    _vm._v(_vm._s(_vm.labels[2])),
                  ])
                : _vm._e(),
            ]
          )
        : _vm.label
        ? _c(
            "label",
            { staticClass: "col-span-2 my-auto text-right text-sm" },
            [_vm._v(_vm._s(_vm.label))]
          )
        : _vm._e(),
      _c("ValidationProvider", {
        class: "col-span-4 relative",
        attrs: {
          tag: "div",
          vid: _vm.fieldId,
          rules: _vm.rules,
          name: _vm.fieldName,
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var errors = ref.errors
              return [
                _c(
                  "p",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.sublabel,
                        expression: "sublabel",
                      },
                    ],
                    staticClass: "text-xxs text-left absolute sub-label",
                  },
                  [_vm._v(_vm._s(_vm.sublabel))]
                ),
                _c(
                  "div",
                  { staticClass: "fl-input-time" },
                  [
                    _c("vue-timepicker", {
                      attrs: {
                        "input-width": "19em",
                        "input-class": {
                          "h-10 border rounded border-brown-grey": true,
                          "bg-red-200 border-red-600": errors.length,
                        },
                        format: "HH:mm:ss",
                        placeholder: "00:00:00",
                      },
                      model: {
                        value: _vm.inputValue,
                        callback: function ($$v) {
                          _vm.inputValue = $$v
                        },
                        expression: "inputValue",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "p",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: errors.length,
                        expression: "errors.length",
                      },
                    ],
                    staticClass: "text-xxs text-left text-red-600 absolute",
                  },
                  [_vm._v(_vm._s(errors[0]))]
                ),
                _c(
                  "p",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.flErrors,
                        expression: "flErrors",
                      },
                    ],
                    staticClass: "text-xxs text-left text-red-600 absolute",
                  },
                  [_vm._v(_vm._s(_vm.flErrors))]
                ),
              ]
            },
          },
        ]),
      }),
      _c(
        "p",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.explanation,
              expression: "explanation",
            },
          ],
          staticClass: "col-span-6 py-3 text-xxs text-left",
        },
        [_c("span", [_vm._v(_vm._s(_vm.explanation))])]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }