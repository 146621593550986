var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mt-4" }, [
    _c("h2", { staticClass: "my-auto text-sm mb-2" }, [
      _vm._v(_vm._s(_vm.$t("commons.fields.awards"))),
    ]),
    _c(
      "div",
      { staticClass: "mb-8 ml-4" },
      [
        _c("FlTable", {
          attrs: {
            titles: _vm.awardTitles,
            hasControls: true,
            alwaysShowNew: true,
            newValue: _vm.newAward,
          },
          on: {
            update: function ($event) {
              return _vm.updateAward($event)
            },
            create: function ($event) {
              return _vm.createAward($event)
            },
          },
          scopedSlots: _vm._u([
            {
              key: "cell",
              fn: function (ref) {
                var cellKey = ref.cellKey
                var rowValue = ref.rowValue
                return [
                  cellKey === "awarded_at"
                    ? _c("span", [_vm._v(_vm._s(_vm.yearOfAward(rowValue)))])
                    : _vm._e(),
                ]
              },
            },
            {
              key: "controls",
              fn: function (ref) {
                var rowValue = ref.rowValue
                var editClicked = ref.editClicked
                return [
                  rowValue.movie_count <= 1
                    ? _c(
                        "button",
                        {
                          staticClass:
                            "px-2 py-1 bg-off-yellow hover:bg-off-yellow-lighter active:bg-off-yellow-darker",
                          on: {
                            click: function ($event) {
                              return editClicked(rowValue)
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("controls.edit")) + " ")]
                      )
                    : _vm._e(),
                  _c(
                    "button",
                    {
                      staticClass:
                        "ml-2 px-2 py-1 bg-off-yellow hover:bg-off-yellow-lighter active:bg-off-yellow-darker",
                      on: {
                        click: function ($event) {
                          return _vm.removeAward(rowValue)
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("controls.delete")) + " ")]
                  ),
                ]
              },
            },
            {
              key: "editingCell",
              fn: function (ref) {
                var rowValue = ref.rowValue
                var isNew = ref.isNew
                return [
                  _c(
                    "td",
                    { staticClass: "py-2 px-1" },
                    [
                      _c("FlInput", {
                        attrs: {
                          fieldId: "name",
                          rules: {
                            max: 255,
                            required: _vm.isNewAwardDirty || !isNew,
                          },
                          label: "日本語名",
                          placeholder: "受賞名",
                        },
                        model: {
                          value: rowValue.translations.ja.name,
                          callback: function ($$v) {
                            _vm.$set(rowValue.translations.ja, "name", $$v)
                          },
                          expression: "rowValue.translations.ja.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "td",
                    { staticClass: "py-2 px-1" },
                    [
                      _c("FlInput", {
                        attrs: {
                          fieldId: "name",
                          rules: {
                            max: 255,
                            english: true,
                            required: _vm.isNewAwardDirty || !isNew,
                          },
                          label: "英語名",
                          placeholder: "award name",
                        },
                        model: {
                          value: rowValue.translations.en.name,
                          callback: function ($$v) {
                            _vm.$set(rowValue.translations.en, "name", $$v)
                          },
                          expression: "rowValue.translations.en.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "td",
                    { staticClass: "py-2 px-1" },
                    [
                      _c("FlSelect2", {
                        attrs: {
                          fieldId: "awarded_at",
                          options: _vm.listSelectableYear,
                          label: "出品・受賞年度",
                          needsTranslation: false,
                          rules: { required: _vm.isNewAwardDirty },
                        },
                        model: {
                          value: rowValue.awarded_at,
                          callback: function ($$v) {
                            _vm.$set(rowValue, "awarded_at", $$v)
                          },
                          expression: "rowValue.awarded_at",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.value.awards,
            callback: function ($$v) {
              _vm.$set(_vm.value, "awards", $$v)
            },
            expression: "value.awards",
          },
        }),
        _vm._m(0),
      ],
      1
    ),
    _c("h2", { staticClass: "my-auto text-sm mb-2" }, [
      _vm._v(_vm._s(_vm.$t("commons.fields.screening"))),
    ]),
    _c(
      "div",
      { staticClass: "mb-8 ml-4" },
      [
        _c("FlTable", {
          attrs: {
            titles: _vm.historyTitles,
            hasControls: true,
            alwaysShowNew: _vm.loginUserRole === "provider",
            newValue: _vm.newPlayHistory,
          },
          on: {
            update: function ($event) {
              return _vm.updatePlayHistory($event)
            },
            create: function ($event) {
              return _vm.createPlayHistory($event)
            },
          },
          scopedSlots: _vm._u([
            {
              key: "cell",
              fn: function (ref) {
                var cellKey = ref.cellKey
                var rowValue = ref.rowValue
                return [
                  cellKey === "start_at"
                    ? _c("span", [
                        _vm._v(_vm._s(_vm.formattedDate(rowValue[cellKey]))),
                      ])
                    : cellKey === "end_at"
                    ? _c("span", [
                        _vm._v(_vm._s(_vm.formattedDate(rowValue[cellKey]))),
                      ])
                    : _vm._e(),
                ]
              },
            },
            {
              key: "controls",
              fn: function (ref) {
                var rowValue = ref.rowValue
                var editClicked = ref.editClicked
                return [
                  _c(
                    "button",
                    {
                      staticClass:
                        "px-2 py-1 bg-off-yellow hover:bg-off-yellow-lighter active:bg-off-yellow-darker",
                      on: {
                        click: function ($event) {
                          return editClicked(rowValue)
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("controls.edit")) + " ")]
                  ),
                  _c(
                    "button",
                    {
                      staticClass:
                        "ml-2 px-2 py-1 bg-off-yellow hover:bg-off-yellow-lighter active:bg-off-yellow-darker",
                      on: {
                        click: function ($event) {
                          return _vm.removePlayHistory(rowValue)
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("controls.delete")) + " ")]
                  ),
                ]
              },
            },
            {
              key: "editingCell",
              fn: function (ref) {
                var rowValue = ref.rowValue
                return [
                  _c(
                    "td",
                    { staticClass: "py-2 px-1" },
                    [
                      _c("FlInput", {
                        attrs: {
                          fieldId: "name",
                          rules: {
                            max: 255,
                            required: _vm.isNewPlayHistoryDirty,
                          },
                          label: "日本語名",
                          placeholder: "上映館名",
                        },
                        model: {
                          value: rowValue.translations.ja.name,
                          callback: function ($$v) {
                            _vm.$set(rowValue.translations.ja, "name", $$v)
                          },
                          expression: "rowValue.translations.ja.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "td",
                    { staticClass: "py-2 px-1" },
                    [
                      _c("FlInput", {
                        attrs: {
                          fieldId: "name",
                          rules: {
                            max: 255,
                            english: true,
                            required: _vm.isNewPlayHistoryDirty,
                          },
                          label: "英語名",
                          placeholder: "played event/theatre name",
                        },
                        model: {
                          value: rowValue.translations.en.name,
                          callback: function ($$v) {
                            _vm.$set(rowValue.translations.en, "name", $$v)
                          },
                          expression: "rowValue.translations.en.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "td",
                    { staticClass: "py-2 px-1" },
                    [
                      _c("FlDate2", {
                        attrs: {
                          fieldId: "start_at",
                          label: "開始",
                          toDate: rowValue.end_at,
                          rules: { required: _vm.isNewPlayHistoryDirty },
                        },
                        model: {
                          value: rowValue.start_at,
                          callback: function ($$v) {
                            _vm.$set(rowValue, "start_at", $$v)
                          },
                          expression: "rowValue.start_at",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "td",
                    { staticClass: "py-2 px-1" },
                    [
                      _c("FlDate2", {
                        attrs: {
                          fieldId: "start_at",
                          label: "終了",
                          fromDate: rowValue.start_at,
                          rules: { required: _vm.isNewPlayHistoryDirty },
                        },
                        model: {
                          value: rowValue.end_at,
                          callback: function ($$v) {
                            _vm.$set(rowValue, "end_at", $$v)
                          },
                          expression: "rowValue.end_at",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.value.play_histories,
            callback: function ($$v) {
              _vm.$set(_vm.value, "play_histories", $$v)
            },
            expression: "value.play_histories",
          },
        }),
        _vm.loginUserRole === "manager"
          ? _c("p", { staticClass: "w-full mt-1 text-left text-sm" }, [
              _c("span", [
                _vm._v(
                  " 運用では編集できません。CHに代理してから編集してください。 "
                ),
              ]),
            ])
          : _vm._e(),
      ],
      1
    ),
    _c("h2", { staticClass: "my-auto text-sm mb-2" }, [
      _vm._v(_vm._s(_vm.$t("commons.fields.vods"))),
    ]),
    _c(
      "div",
      { staticClass: "mb-8 ml-4" },
      [
        _c("FlTable", {
          attrs: {
            titles: _vm.historyTitles,
            hasControls: true,
            alwaysShowNew: _vm.loginUserRole === "provider",
            newValue: _vm.newSvodHistory,
          },
          on: {
            update: function ($event) {
              return _vm.updateSvodHistory($event)
            },
            create: function ($event) {
              return _vm.createSvodHistory($event)
            },
          },
          scopedSlots: _vm._u([
            {
              key: "cell",
              fn: function (ref) {
                var cellKey = ref.cellKey
                var rowValue = ref.rowValue
                return [
                  cellKey === "start_at"
                    ? _c("span", [
                        _vm._v(_vm._s(_vm.formattedDate(rowValue[cellKey]))),
                      ])
                    : cellKey === "end_at"
                    ? _c("span", [
                        _vm._v(_vm._s(_vm.formattedDate(rowValue[cellKey]))),
                      ])
                    : _vm._e(),
                ]
              },
            },
            {
              key: "controls",
              fn: function (ref) {
                var rowValue = ref.rowValue
                var editClicked = ref.editClicked
                return [
                  _c(
                    "button",
                    {
                      staticClass:
                        "px-2 py-1 bg-off-yellow hover:bg-off-yellow-lighter active:bg-off-yellow-darker",
                      on: {
                        click: function ($event) {
                          return editClicked(rowValue)
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("controls.edit")) + " ")]
                  ),
                  _c(
                    "button",
                    {
                      staticClass:
                        "ml-2 px-2 py-1 bg-off-yellow hover:bg-off-yellow-lighter active:bg-off-yellow-darker",
                      on: {
                        click: function ($event) {
                          return _vm.removeSvodHistory(rowValue)
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("controls.delete")) + " ")]
                  ),
                ]
              },
            },
            {
              key: "editingCell",
              fn: function (ref) {
                var rowValue = ref.rowValue
                return [
                  _c(
                    "td",
                    { staticClass: "py-2 px-1" },
                    [
                      _c("FlInput", {
                        attrs: {
                          fieldId: "name",
                          rules: {
                            max: 255,
                            required: _vm.isNewSvodHistoryDirty,
                          },
                          label: "日本語名",
                          placeholder: "VOD名",
                        },
                        model: {
                          value: rowValue.translations.ja.name,
                          callback: function ($$v) {
                            _vm.$set(rowValue.translations.ja, "name", $$v)
                          },
                          expression: "rowValue.translations.ja.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "td",
                    { staticClass: "py-2 px-1" },
                    [
                      _c("FlInput", {
                        attrs: {
                          fieldId: "name",
                          rules: {
                            max: 255,
                            english: true,
                            required: _vm.isNewSvodHistoryDirty,
                          },
                          label: "英語名",
                          placeholder: "vod name",
                        },
                        model: {
                          value: rowValue.translations.en.name,
                          callback: function ($$v) {
                            _vm.$set(rowValue.translations.en, "name", $$v)
                          },
                          expression: "rowValue.translations.en.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "td",
                    { staticClass: "py-2 px-1" },
                    [
                      _c("FlDate2", {
                        attrs: {
                          fieldId: "start_at",
                          label: "開始",
                          toDate: rowValue.end_at,
                          rules: { required: _vm.isNewSvodHistoryDirty },
                        },
                        model: {
                          value: rowValue.start_at,
                          callback: function ($$v) {
                            _vm.$set(rowValue, "start_at", $$v)
                          },
                          expression: "rowValue.start_at",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "td",
                    { staticClass: "py-2 px-1" },
                    [
                      _c("FlDate2", {
                        attrs: {
                          fieldId: "start_at",
                          label: "終了",
                          fromDate: rowValue.start_at,
                          rules: { required: _vm.isNewSvodHistoryDirty },
                        },
                        model: {
                          value: rowValue.end_at,
                          callback: function ($$v) {
                            _vm.$set(rowValue, "end_at", $$v)
                          },
                          expression: "rowValue.end_at",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.value.svod_histories,
            callback: function ($$v) {
              _vm.$set(_vm.value, "svod_histories", $$v)
            },
            expression: "value.svod_histories",
          },
        }),
        _vm.loginUserRole === "manager"
          ? _c("p", { staticClass: "w-full mt-1 text-left text-sm" }, [
              _c("span", [
                _vm._v(
                  " 運用では編集できません。CHに代理してから編集してください。 "
                ),
              ]),
            ])
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "w-full mt-1 text-left text-sm" }, [
      _c("span", [
        _vm._v(
          " [日本語]・[英語]欄に、受賞・上映作品を入力し、上映・受賞した年を選択した後、[Add+]ボタンを押すことで仮登録されます。 "
        ),
      ]),
      _c("span", [
        _vm._v("本登録には「更新ボタン」を押すことで登録されます。"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }