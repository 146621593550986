var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "bg-very-light-grey py-8" }, [
      _c(
        "div",
        {
          staticClass: "container mx-auto grid grid-cols-12 gap-6 gap-y-8 my-8",
        },
        [
          _c(
            "div",
            { staticClass: "col-start-1 col-span-6" },
            [
              _c("fl-select", {
                attrs: {
                  fieldId: "status",
                  options: _vm.status,
                  label: "Status",
                },
                model: {
                  value: _vm.value.status,
                  callback: function ($$v) {
                    _vm.$set(_vm.value, "status", $$v)
                  },
                  expression: "value.status",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-start-1 col-span-6" },
            [
              _c("fl-labeled-input", {
                attrs: {
                  fieldId: "conditions",
                  rules: { max: 255 },
                  label: "Conditions",
                },
                model: {
                  value: _vm.value.conditions,
                  callback: function ($$v) {
                    _vm.$set(_vm.value, "conditions", $$v)
                  },
                  expression: "value.conditions",
                },
              }),
            ],
            1
          ),
          _c(
            "label",
            {
              staticClass: "col-start-1 col-span-2 my-auto text-right text-sm",
            },
            [_vm._v("Selling price")]
          ),
          _c(
            "div",
            { staticClass: "col-span-2" },
            [
              _c("fl-labeled-input", {
                attrs: {
                  fieldId: "selling_price",
                  type: "number",
                  rules: { max: 255 },
                  sublabel: "1.Africa",
                  shortWidth: true,
                },
                model: {
                  value: _vm.value.prices[0].price,
                  callback: function ($$v) {
                    _vm.$set(_vm.value.prices[0], "price", $$v)
                  },
                  expression: "value.prices[0].price",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-span-2" },
            [
              _c("fl-labeled-input", {
                attrs: {
                  fieldId: "selling_price",
                  type: "number",
                  rules: { max: 255 },
                  sublabel: "2.Asia",
                  shortWidth: true,
                },
                model: {
                  value: _vm.value.prices[1].price,
                  callback: function ($$v) {
                    _vm.$set(_vm.value.prices[1], "price", $$v)
                  },
                  expression: "value.prices[1].price",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-span-2" },
            [
              _c("fl-labeled-input", {
                attrs: {
                  fieldId: "selling_price",
                  type: "number",
                  rules: { max: 255 },
                  sublabel: "3.The Caribbean",
                  shortWidth: true,
                },
                model: {
                  value: _vm.value.prices[2].price,
                  callback: function ($$v) {
                    _vm.$set(_vm.value.prices[2], "price", $$v)
                  },
                  expression: "value.prices[2].price",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-span-2" },
            [
              _c("fl-labeled-input", {
                attrs: {
                  fieldId: "selling_price",
                  type: "number",
                  rules: { max: 255 },
                  sublabel: "4.Central America",
                  shortWidth: true,
                },
                model: {
                  value: _vm.value.prices[3].price,
                  callback: function ($$v) {
                    _vm.$set(_vm.value.prices[3], "price", $$v)
                  },
                  expression: "value.prices[3].price",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-start-3 col-span-2" },
            [
              _c("fl-labeled-input", {
                attrs: {
                  fieldId: "selling_price",
                  type: "number",
                  rules: { max: 255 },
                  sublabel: "5.Europe",
                  shortWidth: true,
                },
                model: {
                  value: _vm.value.prices[4].price,
                  callback: function ($$v) {
                    _vm.$set(_vm.value.prices[4], "price", $$v)
                  },
                  expression: "value.prices[4].price",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-span-2" },
            [
              _c("fl-labeled-input", {
                attrs: {
                  fieldId: "selling_price",
                  type: "number",
                  rules: { max: 255 },
                  sublabel: "6.North America",
                  shortWidth: true,
                },
                model: {
                  value: _vm.value.prices[5].price,
                  callback: function ($$v) {
                    _vm.$set(_vm.value.prices[5], "price", $$v)
                  },
                  expression: "value.prices[5].price",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-span-2" },
            [
              _c("fl-labeled-input", {
                attrs: {
                  fieldId: "selling_price",
                  type: "number",
                  rules: { max: 255 },
                  sublabel: "7.Oceania",
                  shortWidth: true,
                },
                model: {
                  value: _vm.value.prices[6].price,
                  callback: function ($$v) {
                    _vm.$set(_vm.value.prices[6], "price", $$v)
                  },
                  expression: "value.prices[6].price",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-span-2" },
            [
              _c("fl-labeled-input", {
                attrs: {
                  fieldId: "selling_price",
                  type: "number",
                  rules: { max: 255 },
                  sublabel: "8.South America",
                  shortWidth: true,
                },
                model: {
                  value: _vm.value.prices[7].price,
                  callback: function ($$v) {
                    _vm.$set(_vm.value.prices[7], "price", $$v)
                  },
                  expression: "value.prices[7].price",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-span-6 col-start-1" },
            [
              _c("fl-select", {
                attrs: {
                  fieldId: "type",
                  type: "number",
                  options: _vm.quality_rank,
                  label: "Quality rank",
                  unselected: true,
                  needsTranslation: false,
                },
                model: {
                  value: _vm.value.quality_rank,
                  callback: function ($$v) {
                    _vm.$set(_vm.value, "quality_rank", $$v)
                  },
                  expression: "value.quality_rank",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-span-6 col-start-1" },
            [
              _c("fl-select", {
                attrs: {
                  fieldId: "type",
                  options: _vm.area,
                  label: "Region",
                  unselected: true,
                },
                model: {
                  value: _vm.value.region,
                  callback: function ($$v) {
                    _vm.$set(_vm.value, "region", $$v)
                  },
                  expression: "value.region",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass:
                "col-span-6 col-start-1 grid gap-x-6 grid-cols-6 items-start",
            },
            [
              _c("label", { staticClass: "col-span-2 text-right text-sm" }, [
                _vm._v(_vm._s(_vm.$t("commons.fields.management_note"))),
              ]),
              _c("div", { staticClass: "col-span-4" }, [
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.value.management_note,
                      expression: "value.management_note",
                    },
                  ],
                  staticClass: "w-full h-40 input_text",
                  domProps: { value: _vm.value.management_note },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.value,
                        "management_note",
                        $event.target.value
                      )
                    },
                  },
                }),
              ]),
            ]
          ),
          _c(
            "div",
            {
              staticClass:
                "col-span-6 col-start-1 grid gap-x-6 grid-cols-6 items-start",
            },
            [
              _c("label", { staticClass: "col-span-2 text-right text-sm" }, [
                _vm._v(
                  _vm._s(_vm.$t("commons.fields.content_note")) +
                    _vm._s(_vm.$t("commons.fields.content_note_sub"))
                ),
              ]),
              _c("div", { staticClass: "col-span-4" }, [
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.value.content_note,
                      expression: "value.content_note",
                    },
                  ],
                  staticClass: "w-full h-40 input_text",
                  domProps: { value: _vm.value.content_note },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.value, "content_note", $event.target.value)
                    },
                  },
                }),
              ]),
            ]
          ),
          _c(
            "div",
            { staticClass: "col-span-6 col-start-3" },
            [
              _c(
                "FlCheckbox",
                {
                  model: {
                    value: _vm.value.is_public,
                    callback: function ($$v) {
                      _vm.$set(_vm.value, "is_public", $$v)
                    },
                    expression: "value.is_public",
                  },
                },
                [_c("span", { staticClass: "pl-2" }, [_vm._v("一般公開する")])]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-start-3 col-span-8" },
            [
              _c(
                "FlCheckbox",
                {
                  model: {
                    value: _vm.value.is_available,
                    callback: function ($$v) {
                      _vm.$set(_vm.value, "is_available", $$v)
                    },
                    expression: "value.is_available",
                  },
                },
                [
                  _c("span", { staticClass: "pl-2" }, [
                    _vm._v("VOD検索一覧に表示する"),
                  ]),
                ]
              ),
              _vm._m(0),
            ],
            1
          ),
        ]
      ),
    ]),
    _c("div", [
      _c(
        "div",
        {
          staticClass: "container mx-auto grid grid-cols-12 gap-6 gap-y-8 my-8",
        },
        [
          _c(
            "div",
            {
              staticClass:
                "col-start-1 col-span-2 my-auto text-right text-sm font-bold",
            },
            [_vm._v("Sales talk")]
          ),
          _c(
            "div",
            { staticClass: "col-span-12" },
            [
              _c("fl-translatable-textarea", {
                attrs: {
                  fieldId: "sns_note",
                  rules: { max: 1000 },
                  rules2: { max: 4000, english: true },
                  label: "SNS",
                },
                model: {
                  value: _vm.value.translations,
                  callback: function ($$v) {
                    _vm.$set(_vm.value, "translations", $$v)
                  },
                  expression: "value.translations",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-span-12" },
            [
              _c("fl-translatable-textarea", {
                attrs: {
                  fieldId: "news_note",
                  rules: { max: 1000 },
                  rules2: { max: 4000, english: true },
                  label: "NEWS",
                },
                model: {
                  value: _vm.value.translations,
                  callback: function ($$v) {
                    _vm.$set(_vm.value, "translations", $$v)
                  },
                  expression: "value.translations",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-span-12" },
            [
              _c("fl-translatable-textarea", {
                attrs: {
                  fieldId: "other_note",
                  rules: { max: 1000 },
                  rules2: { max: 4000, english: true },
                  label: "OTHER",
                },
                model: {
                  value: _vm.value.translations,
                  callback: function ($$v) {
                    _vm.$set(_vm.value, "translations", $$v)
                  },
                  expression: "value.translations",
                },
              }),
            ],
            1
          ),
          _c(
            "label",
            { staticClass: "col-start-1 col-span-2 mt-3 text-right text-sm" },
            [_vm._v("Tag(詳細タブと同じもの)")]
          ),
          _c(
            "div",
            { staticClass: "col-span-10 relative" },
            [
              _c("InputTagsField", {
                model: {
                  value: _vm.value.tags,
                  callback: function ($$v) {
                    _vm.$set(_vm.value, "tags", $$v)
                  },
                  expression: "value.tags",
                },
              }),
              _c(
                "p",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.imageErrors.tags,
                      expression: "imageErrors.tags",
                    },
                  ],
                  staticClass: "text-xs text-left text-red-600 mt-1",
                },
                [_vm._v(_vm._s(_vm.imageErrors.tags))]
              ),
            ],
            1
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("span", { staticClass: "bg-off-yellow-lighter p-1" }, [
        _vm._v(
          "運用閲覧時及びおすすめリストには表示します。VODの検索結果には表示しません。"
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }