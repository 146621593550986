var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("FlInputWrapper", {
    attrs: {
      label: _vm.label,
      sublabel: _vm.sublabel,
      rules: _vm.rules,
      name: _vm.name,
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function (ref) {
          var errors = ref.errors
          return [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.mutableValue,
                  expression: "mutableValue",
                },
              ],
              staticClass: "w-full p-2 h-10 bg-white border rounded",
              class: [
                errors.length
                  ? "bg-red-200 border-red-600"
                  : "border-brown-grey",
                _vm.disabled ? "bg-light-grey" : "",
              ],
              attrs: {
                type: "text",
                name: _vm.name,
                disabled: _vm.disabled,
                placeholder: _vm.placeholder,
                list: _vm.uniqueId,
              },
              domProps: { value: _vm.mutableValue },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.mutableValue = $event.target.value
                },
              },
            }),
            _c(
              "datalist",
              { attrs: { id: _vm.uniqueId } },
              _vm._l(_vm.options, function (option, key) {
                return _c(
                  "option",
                  { key: key, domProps: { value: option.name } },
                  [_vm._v(" " + _vm._s(option.name) + " ")]
                )
              }),
              0
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }