var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._l(_vm.subtitleData, function (subtitle, key) {
        return [
          subtitle
            ? _c("div", { key: key, staticClass: "w-full mb-2" }, [
                _c("div", { staticClass: "w-full grid gap-x-6 grid-cols-12" }, [
                  key === 0
                    ? _c(
                        "label",
                        {
                          staticClass: "col-span-3 my-auto text-right text-sm",
                        },
                        [_vm._v("字幕言語")]
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      staticClass: "input_text col-start-4 col-span-6 relative",
                    },
                    [
                      _c("p", [
                        _vm._v(_vm._s(_vm.subtitleFormat2Text(subtitle))),
                      ]),
                    ]
                  ),
                  _c(
                    "button",
                    {
                      staticClass:
                        "col-span-2 px-3 py-1 bg-brown-grey font-bold float-right h-10 uppercase",
                      on: {
                        click: function ($event) {
                          return _vm.deleteSubtitle(subtitle)
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("components.fl_select_lanugages.delete")
                          ) +
                          " "
                      ),
                    ]
                  ),
                ]),
              ])
            : _vm._e(),
        ]
      }),
      _c("div", { staticClass: "w-full" }, [
        _c("div", { staticClass: "w-full grid gap-x-6 grid-cols-12" }, [
          _vm.subtitleData.length === 0
            ? _c(
                "label",
                { staticClass: "col-span-3 my-auto text-right text-sm" },
                [_vm._v("字幕言語")]
              )
            : _vm._e(),
          _c("div", { staticClass: "col-start-4 col-span-3 relative" }, [
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.newSubtitleLanguage,
                    expression: "newSubtitleLanguage",
                  },
                ],
                staticClass:
                  "w-full h-10 bg-white border rounded border-brown-grey",
                attrs: { name: "add_subtitle" },
                on: {
                  change: function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.newSubtitleLanguage = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                },
              },
              _vm._l(_vm.enableOptions, function (option, key) {
                return _c(
                  "option",
                  { key: key, domProps: { value: option.value } },
                  [_vm._v(" " + _vm._s(_vm.$t(option.text)) + " ")]
                )
              }),
              0
            ),
          ]),
          _c(
            "div",
            { staticClass: "col-span-3 relative" },
            [
              _c("fl-select", {
                attrs: {
                  options: _vm.subtitleFormatOptions,
                  unselected: false,
                  fieldId: "subtitle_file_format_" + _vm.uid,
                },
                model: {
                  value: _vm.newSubtitleLanguageFileFormat,
                  callback: function ($$v) {
                    _vm.newSubtitleLanguageFileFormat = $$v
                  },
                  expression: "newSubtitleLanguageFileFormat",
                },
              }),
            ],
            1
          ),
          _c(
            "button",
            {
              staticClass:
                "col-start-10 col-span-2 px-3 py-1 bg-base-yellow font-bold float-right h-10 uppercase",
              class: [
                _vm.isAddFormValidation()
                  ? "bg-base-yellow"
                  : "bg-light-grey cursor-default",
              ],
              attrs: { disabled: !_vm.isAddFormValidation() },
              on: { click: _vm.addSubtitle },
            },
            [
              _vm._v(
                " " + _vm._s(_vm.$t("components.fl_select_lanugages.add")) + " "
              ),
            ]
          ),
        ]),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }