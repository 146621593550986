var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "grid grid-cols-6 gap-6 gap-y-8 my-8" },
    [
      _vm._l(_vm.castFieldNames, function (cast, key) {
        return [
          _c(
            "div",
            { key: "cast-field-" + key, staticClass: "col-start-1 col-span-3" },
            [
              _c("fl-labeled-input", {
                attrs: {
                  label: "主要キャスト" + (key + 1),
                  rules: { required: key + 1 == 1, max: 255 },
                  fieldId: cast,
                  sublabel: "日本語(50文字以内)",
                },
                model: {
                  value: _vm.value.translations.ja[cast],
                  callback: function ($$v) {
                    _vm.$set(_vm.value.translations.ja, cast, $$v)
                  },
                  expression: "value.translations.ja[cast]",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { key: "cast-field-en-" + key, staticClass: "col-span-3" },
            [
              _c("fl-labeled-input", {
                attrs: {
                  label: "主要キャスト" + (key + 1),
                  sublabel: "英語(50文字以内)",
                  rules: { required: key + 1 == 1, max: 255, english: true },
                  fieldId: cast,
                },
                model: {
                  value: _vm.value.translations.en[cast],
                  callback: function ($$v) {
                    _vm.$set(_vm.value.translations.en, cast, $$v)
                  },
                  expression: "value.translations.en[cast]",
                },
              }),
            ],
            1
          ),
        ]
      }),
      _c(
        "div",
        { staticClass: "col-span-3 col-start-1" },
        [
          _c("fl-labeled-input", {
            attrs: {
              fieldId: "director",
              rules: { required: true, max: 255 },
              label: "監督・演出",
              sublabel: "日本語(50文字以内)",
            },
            model: {
              value: _vm.value.translations.ja.director,
              callback: function ($$v) {
                _vm.$set(_vm.value.translations.ja, "director", $$v)
              },
              expression: "value.translations.ja.director",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-span-3" },
        [
          _c("fl-labeled-input", {
            attrs: {
              fieldId: "director_en",
              rules: { required: true, max: 255, english: true },
              label: "監督・演出",
              sublabel: "英語(50文字以内)",
            },
            model: {
              value: _vm.value.translations.en.director,
              callback: function ($$v) {
                _vm.$set(_vm.value.translations.en, "director", $$v)
              },
              expression: "value.translations.en.director",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-span-3 col-start-1" },
        [
          _c("fl-labeled-input", {
            attrs: {
              fieldId: "copyright_holder",
              label: "著作権者",
              sublabel: "日本語(50文字以内)",
            },
            model: {
              value: _vm.value.translations.ja.copyright_holder,
              callback: function ($$v) {
                _vm.$set(_vm.value.translations.ja, "copyright_holder", $$v)
              },
              expression: "value.translations.ja.copyright_holder",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-span-3" },
        [
          _c("fl-labeled-input", {
            attrs: {
              fieldId: "copyright_holder_en",
              label: "著作権者",
              sublabel: "英語(50文字以内)",
            },
            model: {
              value: _vm.value.translations.en.copyright_holder,
              callback: function ($$v) {
                _vm.$set(_vm.value.translations.en, "copyright_holder", $$v)
              },
              expression: "value.translations.en.copyright_holder",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-start-1 col-span-3" },
        [
          _c("fl-labeled-input", {
            attrs: {
              fieldId: "creator",
              label: "制作者・制作会社・スタジオ",
              sublabel: "日本語(50文字以内)",
            },
            model: {
              value: _vm.value.translations.ja.creator,
              callback: function ($$v) {
                _vm.$set(_vm.value.translations.ja, "creator", $$v)
              },
              expression: "value.translations.ja.creator",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-span-3" },
        [
          _c("fl-labeled-input", {
            attrs: {
              fieldId: "creator_en",
              label: "制作者・制作会社・スタジオ",
              sublabel: "英語(50文字以内)",
            },
            model: {
              value: _vm.value.translations.en.creator,
              callback: function ($$v) {
                _vm.$set(_vm.value.translations.en, "creator", $$v)
              },
              expression: "value.translations.en.creator",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-start-1 col-span-3" },
        [
          _c("fl-labeled-input", {
            attrs: {
              label: "脚本家",
              sublabel: "日本語(50文字以内)",
              rules: { max: 255 },
              fieldId: "script_writer",
            },
            model: {
              value: _vm.value.translations.ja.script_writer,
              callback: function ($$v) {
                _vm.$set(_vm.value.translations.ja, "script_writer", $$v)
              },
              expression: "value.translations.ja.script_writer",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-span-3" },
        [
          _c("fl-labeled-input", {
            attrs: {
              label: "脚本家",
              sublabel: "英語(50文字以内)",
              rules: { max: 255, english: true },
              fieldId: "script_writer",
            },
            model: {
              value: _vm.value.translations.en.script_writer,
              callback: function ($$v) {
                _vm.$set(_vm.value.translations.en, "script_writer", $$v)
              },
              expression: "value.translations.en.script_writer",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-start-1 col-span-3" },
        [
          _c("fl-labeled-input", {
            attrs: {
              label: "撮影担当",
              rules: { max: 255 },
              fieldId: "cinematographer",
              sublabel: "日本語(50文字以内)",
            },
            model: {
              value: _vm.value.translations.ja.cinematographer,
              callback: function ($$v) {
                _vm.$set(_vm.value.translations.ja, "cinematographer", $$v)
              },
              expression: "value.translations.ja.cinematographer",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-span-3" },
        [
          _c("fl-labeled-input", {
            attrs: {
              label: "撮影担当",
              sublabel: "英語(50文字以内)",
              rules: { max: 255, english: true },
              fieldId: "cinematographer",
            },
            model: {
              value: _vm.value.translations.en.cinematographer,
              callback: function ($$v) {
                _vm.$set(_vm.value.translations.en, "cinematographer", $$v)
              },
              expression: "value.translations.en.cinematographer",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-start-1 col-span-3" },
        [
          _c("fl-labeled-input", {
            attrs: {
              label: "音楽",
              sublabel: "日本語(50文字以内)",
              rules: { max: 255 },
              fieldId: "music",
            },
            model: {
              value: _vm.value.translations.ja.music,
              callback: function ($$v) {
                _vm.$set(_vm.value.translations.ja, "music", $$v)
              },
              expression: "value.translations.ja.music",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-span-3" },
        [
          _c("fl-labeled-input", {
            attrs: {
              label: "音楽",
              sublabel: "英語(50文字以内)",
              rules: { max: 255, english: true },
              fieldId: "music",
            },
            model: {
              value: _vm.value.translations.en.music,
              callback: function ($$v) {
                _vm.$set(_vm.value.translations.en, "music", $$v)
              },
              expression: "value.translations.en.music",
            },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }