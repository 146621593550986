var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "grid grid-cols-6 gap-6 gap-y-8 my-8" }, [
    _c(
      "div",
      { staticClass: "col-start-1 col-span-3" },
      [
        _c("fl-select", {
          attrs: {
            label: "本編動画のサイズ",
            options: _vm.movieResolution,
            fieldId: "format",
            unselected: true,
            nullable: true,
          },
          model: {
            value: _vm.value.main_video.videos[0].resolution,
            callback: function ($$v) {
              _vm.$set(_vm.value.main_video.videos[0], "resolution", $$v)
            },
            expression: "value.main_video.videos[0].resolution",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "col-start-1 col-span-3" },
      [
        _c("fl-select", {
          attrs: {
            label: "フレームレート",
            options: _vm.frameRate,
            fieldId: "frame_rate",
            unselected: true,
            nullable: true,
          },
          model: {
            value: _vm.value.main_video.videos[0].frame_rate,
            callback: function ($$v) {
              _vm.$set(_vm.value.main_video.videos[0], "frame_rate", $$v)
            },
            expression: "value.main_video.videos[0].frame_rate",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "col-start-1 col-span-3" },
      [
        _c("fl-select", {
          attrs: {
            label: "縦横比",
            options: _vm.aspectRatio,
            fieldId: "vertical_and_horizontal",
            unselected: true,
            nullable: true,
          },
          model: {
            value: _vm.value.main_video.videos[0].aspect_ratio,
            callback: function ($$v) {
              _vm.$set(_vm.value.main_video.videos[0], "aspect_ratio", $$v)
            },
            expression: "value.main_video.videos[0].aspect_ratio",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "col-start-1 col-span-3" },
      [
        _c("fl-select", {
          attrs: {
            label: "音声モード",
            options: _vm.audioFormat,
            fieldId: "voice_mode",
            unselected: true,
            nullable: true,
          },
          model: {
            value: _vm.value.main_video.videos[0].audio_format,
            callback: function ($$v) {
              _vm.$set(_vm.value.main_video.videos[0], "audio_format", $$v)
            },
            expression: "value.main_video.videos[0].audio_format",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "col-start-1 col-span-6" },
      [
        _c("fl-labeled-input", {
          attrs: {
            label: "音声チャンネル",
            rules: { max: 255 },
            explanation:
              "音声が複数チャンネルに入っている場合はLRの指定を表記ください",
            fieldId: "voice_channel",
          },
          model: {
            value: _vm.value.main_video.videos[0].audio_channel,
            callback: function ($$v) {
              _vm.$set(_vm.value.main_video.videos[0], "audio_channel", $$v)
            },
            expression: "value.main_video.videos[0].audio_channel",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "col-start-1 col-span-3" },
      [
        _c("fl-select", {
          attrs: {
            label: "上映データのフォーマット",
            options: _vm.filmFormat,
            fieldId: "movie_format",
            unselected: true,
          },
          model: {
            value: _vm.value.main_video.videos[0].video_encoding_format,
            callback: function ($$v) {
              _vm.$set(
                _vm.value.main_video.videos[0],
                "video_encoding_format",
                $$v
              )
            },
            expression: "value.main_video.videos[0].video_encoding_format",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "col-start-1 col-span-6" },
      [
        _c("fl-input-time", {
          attrs: {
            labels: ["S.O.M.", "本編スタート時間"],
            explanation:
              "00:24:30のような書き方で記入してください（この場合24分30秒）",
            fieldId: "s_o_m",
          },
          model: {
            value: _vm.value.s_o_m,
            callback: function ($$v) {
              _vm.$set(_vm.value, "s_o_m", $$v)
            },
            expression: "value.s_o_m",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "col-start-1 col-span-6" },
      [
        _c("fl-input-time", {
          attrs: {
            label: "本編尺",
            explanation:
              "00:24:30のような書き方で記入してください（この場合24分30秒）",
            fieldId: "movie_lange_time",
          },
          model: {
            value: _vm.value.duration,
            callback: function ($$v) {
              _vm.$set(_vm.value, "duration", $$v)
            },
            expression: "value.duration",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "col-start-1 col-span-6" },
      [
        _c("fl-url-input", {
          attrs: {
            vid: "trailer_url",
            type: "text",
            label: "予告編動画",
            explanation: "Youtubeのリンク及びVimeoリンクに対応しています。",
            rules: { required: false, url: true },
          },
          model: {
            value: _vm.value.trailer_url,
            callback: function ($$v) {
              _vm.$set(_vm.value, "trailer_url", $$v)
            },
            expression: "value.trailer_url",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "col-span-3 col-start-1" },
      [
        _c("fl-url-input", {
          attrs: {
            fieldId: "screener_url",
            rules: { required: false, url: true },
            label: "本編スクリーナー",
          },
          model: {
            value: _vm.value.screener_url,
            callback: function ($$v) {
              _vm.$set(_vm.value, "screener_url", $$v)
            },
            expression: "value.screener_url",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "col-span-3" },
      [
        _c("fl-labeled-input", {
          attrs: {
            fieldId: "screener_password",
            rules: { max: 255 },
            labels: ["本編スクリーナー", "パスワード"],
            sublabel: "任意",
          },
          model: {
            value: _vm.value.screener_password,
            callback: function ($$v) {
              _vm.$set(_vm.value, "screener_password", $$v)
            },
            expression: "value.screener_password",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }